/* ==============================================================================================
 * MODIFY BORROWER NAME
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
    "use strict";

    var input = document.querySelector("[data-element='borrowerName']");
    var modifyButton = document.querySelector("[data-element='modifyBorrowerName']");
    var saveButton = document.querySelector("[data-element='saveBorrowerName']");
    var warningMessage = document.querySelector("[data-element='borrowerNameWarning']");
    var resetButton = document.querySelector("[data-element='resetButton']");

	if (!input || !modifyButton || !saveButton) {
		return;
    }

    if (resetButton) {
        resetButton.addEventListener("click", function () {
            window.scrollTo(0,0);
            location.reload(true);
        });
    }

    modifyButton.addEventListener("click", function () {
        input.disabled = false;
        hide(modifyButton);
        show(saveButton.parentElement);
        show(warningMessage);
    });

    saveButton.addEventListener("click", function () {
        input.disabled = true;
        show(modifyButton);
        hide(saveButton.parentElement);
        hide(warningMessage);
    });

    /**
     * Hides element from the page
     * @param {element} element 
     */
    function hide(element) {
        element.classList.add("_dn");
    }

    /**
     * Shows hidden element on the page
     * @param {element} element 
     */
    function show(element) {
        element.classList.remove("_dn");
    }

})(Sushi || (Sushi = {}));
