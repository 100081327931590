/* ==============================================================================================
 * INITIALIZE APP
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	/**
	 * Detects and loads fallback images
	 */
	function loadFallbackImages() {
		var fallbackImages = document.querySelectorAll(".c-fallbackImage");

		Array.prototype.forEach.call(fallbackImages, function (image) {

			image.setAttribute("src", image.getAttribute("data-src"));
			image.classList.remove("_dn");
		});
	}

	/**
	 * IF browser is IE or Edge add class to html tag and load fallback images
	 */
	if (Sushi.Util.detectIe()) {
		document.getElementsByTagName("html")[0].classList.add("ie");

		loadFallbackImages();
	}

	var permissionForm = document.querySelectorAll("[name=requestPermission]");
	var stepperInstance = Sushi.getPluginInstance("Stepper", document.querySelector("[data-plugin=Stepper]"));
	var returningCustomerForm = document.querySelector(".c-returningCustomerForm");

	if (permissionForm && stepperInstance) {
		Array.prototype.forEach.call(permissionForm, function (form) {
			form.addEventListener("click", function (event) {
				event.currentTarget.value === "grant"
					? stepperInstance.toggleLastStep(true)
					: stepperInstance.toggleLastStep(false);
			});
		});
	}
	else if (permissionForm && returningCustomerForm) {
		Array.prototype.forEach.call(permissionForm, function (form) {
			form.addEventListener("click", function (event) {
				var formSteps = returningCustomerForm.querySelectorAll(".c-stepper__step");
				var lastStep = formSteps[formSteps.length - 1];

				if (!lastStep) {
					return;
				}

				event.currentTarget.value === "grant"
					? lastStep.classList.remove("_dn")
					: lastStep.classList.add("_dn");
			});
		});
	}

})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));