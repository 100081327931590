var Sushi;

(function (Sushi) {
	"use strict";

	var classes = {
		instanceSelector: ".c-fadeScrollable",
		trackSelector: ".c-fadeScrollable__track",
		contentSelector: ".c-fadeScrollable__content",
		stateStart: "c-fadeScrollable--fadeStart",
		stateEnd: "c-fadeScrollable--fadeEnd",
	};

	var instances = document.querySelectorAll(classes.instanceSelector);
	var scrollThreshold = 25;

	Sushi.Dom.forEach(instances, function (scrollable) {
		var track = scrollable.querySelector(classes.trackSelector);
		var content = scrollable.querySelector(classes.contentSelector);

		var maxScrollAmount = content.clientWidth - track.clientWidth;
		var scrollAmount = 0;

		if (maxScrollAmount > scrollThreshold) {
			scrollable.classList.add(classes.stateEnd);
		}

		track.addEventListener("scroll", function () {
			scrollAmount = track.scrollLeft;

			scrollAmount > scrollThreshold
				? scrollable.classList.add(classes.stateStart)
				: scrollable.classList.remove(classes.stateStart);

			maxScrollAmount - scrollAmount > scrollThreshold
				? scrollable.classList.add(classes.stateEnd)
				: scrollable.classList.remove(classes.stateEnd);
		});

		window.addEventListener("resize", function () {
			maxScrollAmount = content.clientWidth - track.clientWidth;
		});
	});
})(Sushi || (Sushi = {}));
