/* ==============================================================================================
 * VALIDATION
 *
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var Validation = {};

	Validation.isFilled = function (value) {
		return (!value || value === "") ? "This field is mandatory" : true;
	};

	Validation.isNumber = function (value) {
		return (!isNaN(parseInt(value, 10))) ? true : "Please enter a valid number";
	};

	Validation.isEmail = function (value) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


		return re.test(value) ? true : "Please enter a valid e-mail";
	};

	Sushi.Validation = Validation;


})(Sushi || (Sushi = {}));
