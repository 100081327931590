/* ==============================================================================================
 * CAROUSEL
 *
 * This plugin is a mix between custom functionality and the Swiper plugin, which is already a
 * carousel implementation. This class will instantiate the Swiper plugin and handle the transition
 * between items, as well as the animation of the progress bars.
 *
 * Basic workflow:
 * - The "slideChange" event will trigger an update of the current slide and controller.
 *
 * “U can't touch this”
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	var BasePlugin = Plugins.BasePlugin;
	var Dom = Sushi.Dom;
	var Events = Sushi.Events;

	var Carousel = function (triggerElement, options) {
		BasePlugin.call(this, triggerElement, options);

		this.timeout = null;
		this.iconAnimationConfig = [];
		this.swiper = null;
		this.isPlaying = false;

		this.container = this.triggerElement.querySelector("[data-carousel-container]");
		this.items = this.triggerElement.querySelectorAll("[data-carousel-item]");
		this.controllers = this.triggerElement.querySelectorAll("[data-carousel-controller]");

		this.parseOptions();
		this.initSwiperPlugin();
		this.initIconAnimations();
		this.registerListeners();

		this.swiper.init();
	};

	Carousel.displayName = "Carousel";

	Carousel.DEFAULTS = {
		slideDuration: 5, // in seconds
		iconAnimationDelay: .4, // in seconds
	};

	Carousel.prototype = Object.create(BasePlugin.prototype);

	var proto = Carousel.prototype;

	proto.constructor = Carousel;

	proto.parseOptions = function () {
		this.options.slideDuration = Number(this.options.slideDuration) * 1000;
		this.options.iconAnimationDelay = Number(this.options.iconAnimationDelay) * 1000;
	};

	proto.initSwiperPlugin = function () {
		var options = {
			init: false,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
			direction: "horizontal",
			loop: false,
			autoplay: false,
			breakpoints: {
				300: {
					noSwiping: true,
					noSwipingSelector: ".c-carousel__controller",
					allowSlidePrev: true,
					allowSlideNext: true,
				},
				900: {
					noSwiping: true,
					noSwipingSelector: ".c-carousel",
				},
			},
		};

		this.swiper =  new window.Swiper(this.container, options);
	};

	proto.initIconAnimations = function () {
		if (!window.bodymovin) {
			// eslint-disable-next-line no-console
			console.log(
				"Lottie's JS global variable could not be found."
				+ " Aborting initialization of animations."
			);

			return false;
		}

		if (Sushi.Util.detectIe()) {
			return false;
		}

		Dom.forEach(this.items, function (itemElement, index) {
			var animationContainer = itemElement.querySelector("[data-animation]");

			if (animationContainer === null) {
				return;
			}

			animationContainer.innerHTML = "";

			this.iconAnimationConfig.push({
				itemIndex: index, // some items may not have an animated icon...
				animation: loadAnimation(animationContainer),
				speed: itemElement.dataset.animationSpeed || 1,
			});
		}.bind(this));
	};

	proto.registerListeners = function () {
		Dom.forEach(this.controllers, function (controllerElement, index) {
			Events(controllerElement).on("click", this.slideTo.bind(this, index));
		}.bind(this));

		this.swiper.on("init slideChangeTransitionStart", this.onSlideStart.bind(this));
		this.swiper.on("slideChange", this.onSlideEnd.bind(this));
	};

	proto.pause = function () {
		if (!this.isPlaying) {
			return;
		}

		this.isPlaying = false;

		this.getCurrentItemController().classList.remove("is-active");

		clearTimeout(this.timeout);

		this.timeout = null;
	};

	proto.play = function () {
		if (this.isPlaying) {
			return;
		}

		this.isPlaying = true;

		this.getCurrentItemController().classList.add("is-active");

		clearTimeout(this.timeout);

		this.timeout = setTimeout(this.next.bind(this), this.options.slideDuration);
	};

	proto.onSlideStart = function () {
		Dom.removeClass(this.controllers, "is-active");

		this.getCurrentItemController().classList.remove("is-previous");
		this.getCurrentItemController().classList.add("is-active");

		if (! Sushi.Util.detectIe()) {
			this.playIconAnimations();
		}

		this.pause();
		this.play();
	};

	proto.onSlideEnd = function () {
		this.getCurrentItemController().classList.add("is-previous");
	};

	proto.next = function () {
		this.slideTo((this.getCurrentItemIndex() + 1) % this.items.length);
	};

	proto.playIconAnimations = function () {
		var currentItemIndex =  this.getCurrentItemIndex();

		this.iconAnimationConfig.forEach(function (animationConfig) {
			if (animationConfig.itemIndex !== currentItemIndex) {
				rewindIconAnimation(animationConfig.animation);

				return;
			}

			setTimeout(function () {
				playIconAnimation(animationConfig.animation, animationConfig.speed);
			}, this.options.iconAnimationDelay);
		}.bind(this));
	};

	proto.getCurrentItemIndex = function () {
		return Number(this.getCurrentItem().dataset.index);
	};

	proto.getCurrentItem = function () {
		return this.container.querySelector(".swiper-slide-active");
	};

	proto.getCurrentItemController = function () {
		return this.controllers[this.getCurrentItemIndex()];
	};

	proto.slideTo = function (slideIndex) {
		this.swiper.slideTo(Number(slideIndex));
	};

	var loadAnimation = function (animationContainer) {
		return window.bodymovin.loadAnimation({
			container: animationContainer,
			renderer: "svg",
			loop: false,
			autoplay: false,
			path: animationContainer.dataset.animation,
		});
	};

	var playIconAnimation = function (animationInstance, speed) {
		animationInstance.setSpeed(speed);
		animationInstance.setDirection(1);
		animationInstance.play();
	};

	var rewindIconAnimation = function (animationInstance) {
		animationInstance.setSpeed(5);
		animationInstance.setDirection(-1);
		animationInstance.play();
	};

	Plugins.Carousel = Carousel;
})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
