(function (Sushi, Plugins) {

	var BasePlugin = Plugins.BasePlugin;
	var Events = Sushi.Events;
	var Util = Sushi.Util;

	var Stepper = function (triggerElement, options) {
		BasePlugin.call(this, triggerElement, options);

		this.currentStep = 0;
		this.steps = [];

		triggerElement.querySelectorAll("[data-step]").forEach(function (el, i) {
			this.steps[i] = {
				element: el,
				enabled: true
			}
		}.bind(this));

		this.buttonPrevious = triggerElement.querySelector("[data-button-previous]");
		this.buttonNext = triggerElement.querySelector("[data-button-next]");
		this.currentStepDisplay = triggerElement.querySelector("[data-current-step]");
		this.totalStepsDisplay = triggerElement.querySelector("[data-total-steps]");
		this.editIcons = triggerElement.querySelectorAll("[data-stepper-edit-icon]");
		this.triggers = triggerElement.querySelectorAll("[data-go-to-step]");

		if (this.buttonNext) {
			this.buttonNextText = this.buttonNext.querySelector("[data-text]");
		}

		if (this.steps.length === 0
			|| !this.buttonPrevious
			|| !this.buttonNext
			|| !this.currentStepDisplay
			|| !this.totalStepsDisplay) {
			return;
		}

		this.initReveals();
		this.addEventListeners();
		this.step(1);
		this.updateTotalStepsDisplay();
	};

	Stepper.displayName = "Stepper";

	Stepper.prototype = Object.create(BasePlugin.prototype);

	var proto = Stepper.prototype;

	proto.constructor = Stepper;

	proto.iterateOverSteps = function (callback) {
		for (var index = 0; index < this.steps.length; index++) {
			var step = this.steps[index];

			callback(step, index);
		}
	};

	proto.initReveals = function () {
		var reveals = [];

		this.iterateOverSteps(function (step, index) {
			var trigger = step.element.querySelector("[data-reveal]");

			if (index > 0) {
				step.element.classList.add("_dn");
			}

			if (!trigger) {
				return;
			}

			reveals.push(new Plugins.Reveal(trigger));
		});

		this.reveals = reveals;
	};

	proto.next = function () {
		var stepper = this;
		var currentStepValue = Number(this.currentStepDisplay.getAttribute("data-current-step"));

		if (currentStepValue === this.getStepsLength()) {
			return;
		}

		this.iterateOverSteps(function (step, index) {
			if (index === currentStepValue) {
				step.element.classList.remove("_dn");
			}
		});

		currentStepValue++;

		setTimeout(function () {
			stepper.step(currentStepValue);
		});
	};

	proto.previous = function () {
		var stepper = this;
		var currentStepValue = Number(this.currentStepDisplay.getAttribute("data-current-step"));

		if (currentStepValue === 1) {
			return;
		}

		currentStepValue--;

		setTimeout(function () {
			stepper.step(currentStepValue);
		});
	};

	proto.step = function (stepNumber) {
		var closingStepNumber = this.currentStep;

		this.currentStep = stepNumber;

		this.updateStepPaginationDisplay();
		this.updateEditIcons();
		this.updateBackButtonVisibility();
		this.updateForwardButtonText();

		if (closingStepNumber) {
			var closingStepElement = this.getStepRevealInstance(closingStepNumber).triggerElement;

			this.closeStep(closingStepNumber);

			Events(closingStepElement)
				.on("Reveal.closed", this.scrollToStep.bind(this, this.currentStep));

			/**
			 * After the animation is over, remove the event listener from the element
			 * 400ms gives enough time for the reveal animation to finish.
			 */
			setTimeout(function () {
				Events(closingStepElement).off("Reveal.closed");
			}, 400);
		}

		this.openStep(this.currentStep);
	};

	proto.updateStepPaginationDisplay = function () {
		this.currentStepDisplay.innerHTML = this.currentStep;
		this.currentStepDisplay.setAttribute("data-current-step", this.currentStep);
	};

	proto.updateTotalStepsDisplay = function () {
		this.totalStepsDisplay.innerHTML = this.getStepsLength();
	};

	proto.updateEditIcons = function () {
		for (var iconIndex = 0; iconIndex < this.editIcons.length; iconIndex++) {
			this.editIcons[iconIndex].classList.remove("_dn");

			if (iconIndex === this.currentStep - 1) {
				this.editIcons[iconIndex].classList.add("_dn");
			}
		}
	};

	proto.updateBackButtonVisibility = function () {
		if (this.currentStep === 1) {
			this.buttonPrevious.classList.add("_dn");
		}
		else {
			this.buttonPrevious.classList.remove("_dn");
		}
	};

	proto.updateForwardButtonText = function () {
		if (this.buttonNextText) {
			this.buttonNextText.innerHTML = (this.currentStep === this.getStepsLength()
				? "Submit"
				: "Next"
			);
		}
	};

	proto.closeStep = function (stepNumber) {
		this.getStep(stepNumber).element.classList.remove("is-active");
		this.getStepRevealInstance(stepNumber).close();
	};

	proto.openStep = function (stepNumber) {
		this.getStep(stepNumber).element.classList.add("is-active");
		this.getStepRevealInstance(stepNumber).open();
	};

	proto.getStepRevealInstance = function (stepNumber) {
		return Sushi.getPluginInstance(
			Plugins.Reveal,
			this.getStep(stepNumber).element.querySelector(".c-reveal")
		);
	};

	proto.getStep = function (stepNumber) {
		return this.steps[stepNumber - 1];
	};

	proto.scrollToStep = function (stepNumber) {
		var stepContainer = this.getStepRevealInstance(stepNumber).triggerElement
			.closest(".c-stepper__step");

		Util.scrollToElement(stepContainer);
	};

	proto.getStepsLength = function () {
		return this.steps.filter(function (step) {
			return step.enabled;
		}).length;
	}

	proto.toggleLastStep = function (enable) {
		var lastStep = this.steps.length - 1;

		if (enable) {
			this.steps[lastStep].enabled = true;
		} else {
			if (this.currentStep == lastStep + 1) {
				this.previous();
			}
			this.steps[lastStep].element.classList.add("_dn");
			this.steps[lastStep].enabled = false;
		}

		this.updateTotalStepsDisplay();
		this.updateForwardButtonText();
	}

	proto.addEventListeners = function () {
		var stepper = this;

		this.buttonNext.addEventListener("click", function () {
			stepper.next();
		});

		this.buttonPrevious.addEventListener("click", function () {
			stepper.previous();
		});

		for (var triggerIndex = 0; triggerIndex < this.triggers.length; triggerIndex++) {
			var trigger = this.triggers[triggerIndex];

			trigger.addEventListener("click", function (event) {
				var element = event.currentTarget;
				var step = Number(element.getAttribute("data-go-to-step"));

				if (!step) {
					return;
				}

				stepper.step(step);
			});
		}
	};

	Plugins.Stepper = Stepper;

})(window.Sushi || (window.Sushi = {}), window.Sushi.Plugins || (window.Sushi.Plugins = {}));
