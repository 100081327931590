/* ==============================================================================================
 * FLOATING LABEL
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	var BasePlugin = Plugins.BasePlugin;
	var Events = Sushi.Events;

	var FloatingLabel = function (triggerElement, options) {
		BasePlugin.call(this, triggerElement, options);

		this.labelElement = this.getLabel() || this.createLabel();

		this.triggerElement.insertAdjacentElement("beforebegin", this.labelElement);

		this.labelElement.classList.add("c-floatingLabel");

		this.triggerElement.removeAttribute("placeholder");

		this.update();

		this.registerListeners();
	};

	FloatingLabel.displayName = "FloatingLabel";

	FloatingLabel.prototype = Object.create(BasePlugin.prototype);

	var proto = FloatingLabel.prototype;

	proto.constructor = FloatingLabel;

	proto.getLabel = function () {
		return this.triggerElement.parentElement
			.querySelector("label[for='" + this.triggerElement.id + "']");
	};

	proto.createLabel = function () {
		var label = document.createElement("label");

		label.setAttribute("for", this.triggerElement.id);
		label.innerText = (this.triggerElement.dataset.label
			|| this.triggerElement.getAttribute("placeholder")
		);

		return label;
	};

	proto.registerListeners = function () {
		if (this.triggerElement.tagName.toLowerCase() === "select") {
			Events(this.triggerElement).on("FloatingLabel.change", this.update.bind(this));

			return;
		}

		Events(this.triggerElement)
			.on("FloatingLabel.input FloatingLabel.change", this.update.bind(this))
			.on("FloatingLabel.focus", handleFocus.bind(this))
			.on("FloatingLabel.blur", this.update.bind(this));
	};

	proto.update = function () {
		var isFilled;

		if (this.triggerElement.tagName.toLowerCase() === "select") {
			isFilled = (this.triggerElement.selectedOptions.item(0)
				&& this.triggerElement.selectedOptions.item(0).value !== ""
			);
		}
		else {
			isFilled = this.triggerElement.value !== "";
		}

		if (isFilled) {
			this.addFloatingClass();
		}
		else {
			this.removeFloatingClass();
		}
	};

	proto.addFloatingClass = function () {
		this.labelElement.classList.add("is-floating");
	};

	proto.removeFloatingClass = function () {
		this.labelElement.classList.remove("is-floating");
	};

	var handleFocus = function () {
		this.addFloatingClass();
	};

	Plugins.FloatingLabel = FloatingLabel;
})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
