/* ==============================================================================================
 * REQUEST SUBMITTED MODAL
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
    "use strict";
    
    // var Modal = Sushi.Plugins.Modal;

    // var modalTrigger = document.getElementById("requestSubmitted");

    // var modal = new Modal(element);

})(Sushi || (Sushi = {}));
