(function () {
	var trigger = document.querySelector("._scrollToTop");

	if (!trigger) {
		return;
	}

	trigger.addEventListener("click", function () {
		document.querySelector("#header").scrollIntoView({
			behavior: "smooth",
		});
	});
})();
