/* ==============================================================================================
 * FILE UPLOAD
 *
 * How To Use
 * Create an element and add data-plugin="FileUpload" to it.
 *
 * Options
 * - inputName: String with the name for the file inputs.
 *   Will be converted to array. Ex: files will become files[]
 * - fileTypes: String with accepted file types separated by commas. Ex:
 *   application/doc,application/docx,application/pdf,application/xls,application/xlsx
 * - maxFileNumber: Maximum number of files the user can upload
 * - maxFileSize: Maximum file size allowed in human readable format.
 *   Should use B, KB, MB, GB, or TB. Ex: 15MB
 *   For infinite files allowed, use the value "infinite"
 * - modifier: String with modifier class to be applied to the main element.
 * - additionalText: String with additional instructions text
 *
 * Slots
 * There are two slots available for markup customization. To use create a script tag inside
 * the trigger element with type="text/html" and add:
 * data-slot="content"
 * or
 * data-slot="fileListItem"
 *
 * The content slot will replace the default content template and can receive the following
 * string placeholders that will have their values replaced dynamically:
 * __inputName__
 * __fileTypes__
 * __maxFileNumber__
 * __maxFileSize__
 *
 * The fileListItem slot will replace the default file list item template and can receive the
 * following string placeholders that will have their values replaced dynamically:
 * with the file data:
 * __fileName__
 * __fileSize__
 * __fileType__
 *
 * Default example:
  * <div
 * 		data-plugin="FileUpload"
 * 		data-file-upload-input-name="files"
 * 		data-file-upload-file-types="application/doc,application/docx,application/pdf"
 * 		data-file-upload-max-file-number="infinite"
 * 		data-file-upload-max-file-size="1MB"
 * ></div>
 *
 * Slots example:
 * <div
 * 		data-plugin="FileUpload"
 * 		data-file-upload-input-name="files2"
 * 		data-file-upload-file-types="application/doc,application/docx,application/pdf"
 * 		data-file-upload-max-file-number="5"
 * 		data-file-upload-max-file-size="1MB"
 * >
 * 		<script type="text/html" data-slot="content">
 * 			<h3 class="c-fileUpload__title">Drop your files or click here to upload</h3>
 * 			<p class=\"c-fileUpload__text\">__additionalText__</p>
 * 			<p class="c-fileUpload__text">
 * 				Please note that you can upload up to
 * 				<strong>__maxFileNumber__ attachments</strong>, and each file should not exceed
 * 				<strong>__maxFileSize__ in size.</strong>
 * 			</p>
 * 		</script>
 * 		<script type="text/html" data-slot="fileListItem">
 * 			<span>__fileName__</span>
 * 			<span>__fileSize__</span>
 * 			<span>__fileType__</span>
 * 			<button data-file-upload-remove>x</button>
 * 		</script>
 * </div>
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	var BasePlugin = Plugins.BasePlugin;
	var Dom = Sushi.Dom;
	var Events = Sushi.Events;

	var FileUpload = function (triggerElement, options) {
		BasePlugin.call(this, triggerElement, options);

		if (!this.validateOptions()) {
			return;
		}

		this.element = triggerElement;

		if (! this.options.dragAndDrop) {
			this.createFallbackInputs();

			return;
		}

		var contentSlot = this.getSlot(this.element.innerHTML, "content");
		var listItemSlot = this.getSlot(this.element.innerHTML, "fileListItem");

		this.hasInlineTemplate = (contentSlot && contentSlot.innerHTML);
		this.files = [];
		this.placeholderIdentifier = "placeholder" + this.options.inputName;

		var contentHtml = (this.hasInlineTemplate
			? contentSlot.innerHTML
			: this.options.template
		);

		this.template = this.nodeListToArray(this.parseElement(contentHtml));

		if (listItemSlot && listItemSlot.innerHTML) {
			this.listItemTemplate = listItemSlot.innerHTML;
		}

		this.create();
		this.registerListeners();
	};

	FileUpload.displayName = "FileUpload";

	FileUpload.DEFAULTS = {
		dragAndDrop: true,
		inputName: null,
		fileTypes: null,
		maxFileNumber: 1,
		maxFileSize: "1MB",
		additionalText: "You may attach Word, Excel, and PDF files to this request.",
		template: "<div>"
			+ "		<h3 class=\"c-fileUpload__title\">"
			+ "			Drop your files or click here to upload"
			+ "		</h3>"
			+ "		<p class=\"c-fileUpload__text\"><strong>__additionalText__</strong></p>"
			+ "		<p class=\"c-fileUpload__text\">"
			+ "			Please note that you can upload up to"
			+ "			<strong>__maxFileNumber__ attachments</strong>,"
			+ "			and each file should not exceed "
			+ "			<strong>__maxFileSize__ in size.</strong>"
			+ "		</p>"
			+ "</div>",
	};

	FileUpload.prototype = Object.create(BasePlugin.prototype);

	var proto = FileUpload.prototype;

	proto.constructor = FileUpload;

	/**
	 * Validate required plugin options
	 * @returns {Boolean}
	 */
	proto.validateOptions = function () {
		if (!this.options.inputName) {
			// eslint-disable-next-line no-console
			console.warn(
				this.constructor.displayName
				+ "needs a data-file-upload-input-name attribute."
			);

			return false;
		}

		if (!this.options.fileTypes) {
			// eslint-disable-next-line no-console
			console.warn(
				this.constructor.displayName
				+ "needs a data-file-upload-file-types attribute."
			);

			return false;
		}

		return true;
	};

	proto.create = function () {
		this.wrapper = this.createWrapper();
		this.dropArea = this.createDropArea();
		this.dropAreaMask = this.createDropAreaMask();
		this.inputPlaceHolder = this.createInputPlaceHolder();
		this.contentWrapper = this.createContentWrapper();
		this.fileListWrapper = this.createFileListWrapper();

		for (var i = 0; i < this.template.length; i++) {
			this.contentWrapper.appendChild(this.template[i]);
		}

		this.dropArea.appendChild(this.dropAreaMask);
		this.dropArea.appendChild(this.inputPlaceHolder);
		this.dropArea.appendChild(this.contentWrapper);

		this.wrapper.appendChild(this.dropArea);
		this.wrapper.appendChild(this.fileListWrapper);

		this.element.innerHTML = "";
		this.element.appendChild(this.wrapper);
	};

	proto.createFallbackInputs = function () {
		this.wrapper = this.createWrapper();
		this.wrapper.classList.add("c-fileUpload--fallback");

		var list = this.createFileListElement();

		for (var i = 0; i < this.options.maxFileNumber; i++) {
			var listItem = this.createFileListItemElement();
			var input = this.createFileListInputElement();

			listItem.appendChild(input);
			list.appendChild(listItem);
		}

		var additionalText = document.createElement("p");

		additionalText.classList.add("c-fileUpload__text");
		additionalText.innerHTML = this.parsePlaceholderText("<strong>__additionalText__</strong>");

		var text = document.createElement("p");

		text.classList.add("c-fileUpload__text");
		additionalText.classList.add("c-fileUpload__text");
		text.innerHTML = this.parsePlaceholderText("Please note that you can upload up to "
			+ "<strong>__maxFileNumber__ attachments</strong>, "
			+ "and each file should not exceed "
			+ "<strong>__maxFileSize__ in size.</strong>");

		this.wrapper.appendChild(additionalText);
		this.wrapper.appendChild(text);
		this.wrapper.appendChild(list);
		this.element.innerHTML = "";
		this.element.appendChild(this.wrapper);
	}

	/**
	 * Creates wrapper element
	 * @returns HTMLElement
	 */
	proto.createWrapper = function () {
		var wrapper = document.createElement("div");

		wrapper.classList.add("c-fileUpload");

		if (this.options.modifier) {
			wrapper.classList.add("c-fileUpload--" + this.options.modifier);
		}

		return wrapper;
	};

	/**
	 * Creates dropArea element
	 * @returns HTMLElement
	 */
	proto.createDropArea = function () {
		var dropArea = document.createElement("div");

		dropArea.classList.add("c-fileUpload__dropArea");

		return dropArea;
	};

	/**
	 * Creates dropAreaMask element
	 * @returns HTMLElement
	 */
	proto.createDropAreaMask = function () {
		var dropAreaMask = document.createElement("label");

		dropAreaMask.classList.add("c-fileUpload__dropAreaMask");
		dropAreaMask.setAttribute("for", this.placeholderIdentifier);

		return dropAreaMask;
	};

	/**
	 * Creates inputPlaceHolder element
	 * @returns HTMLElement
	 */
	proto.createInputPlaceHolder = function () {
		var inputPlaceHolder = document.createElement("input");

		inputPlaceHolder.classList.add("c-fileUpload__inputPlaceHolder");
		inputPlaceHolder.setAttribute("type", "file");
		inputPlaceHolder.setAttribute("id", this.placeholderIdentifier);
		inputPlaceHolder.setAttribute("accept", this.options.fileTypes);

		if (Number(this.options.maxFileNumber) !== 1) {
			inputPlaceHolder.setAttribute("multiple", "true");
		}

		return inputPlaceHolder;
	};

	/**
	 * Creates contentWrapper element
	 * @returns HTMLElement
	 */
	proto.createContentWrapper = function () {
		var contentWrapper = document.createElement("div");

		contentWrapper.classList.add("c-fileUpload__contentWrapper");

		return contentWrapper;
	};

	/**
	 * Creates fileListWrapper element
	 * @returns HTMLElement
	 */
	proto.createFileListWrapper = function () {
		var fileListWrapper = document.createElement("div");

		fileListWrapper.classList.add("c-fileUpload__fileListWrapper");

		return fileListWrapper;
	};

	/**
	 * Registers the event listeners for the plugin
	 */
	proto.registerListeners = function () {
		Events(this.inputPlaceHolder).on(
			"FileUpload.change",
			handleInputPlaceHolderChange.bind(this)
		);
		Events(this.fileListWrapper).on(
			"FileUpload.dragenter",
			handleDragEnter.bind(this)
		);
		Events(this.dropArea).on(
			"FileUpload.dragover",
			handleDragOver.bind(this)
		);
		Events(this.dropAreaMask).on(
			"FileUpload.dragleave",
			handleDragLeave.bind(this)
		);
		Events(this.dropAreaMask).on(
			"FileUpload.drop",
			handleDrop.bind(this)
		);
	};

	/**
	 * Creates the file list elements and appends it to the page
	 */
	proto.parseFilesList = function () {
		var list;

		if (this.listItemTemplate) {
			list = this.createFileListFromTemplate();
		}
		else {
			list = this.createDefaultFileList();
		}

		if (!this.hitLimit()) {
			var addAnotherFileItem = this.createFileAddingButton();

			list.appendChild(addAnotherFileItem);
		}

		var listWrapper = this.element.querySelector(".c-fileUpload__fileListWrapper");

		if (this.files.length > 0) {
			this.wrapper.classList.add("has-files");
			listWrapper.innerHTML = "";
			listWrapper.appendChild(list);
		}
		else {
			this.wrapper.classList.remove("has-files");
			listWrapper.innerHTML = "";
		}
	};

	/**
	 * Creates the file list from a template
	 * @returns HTMLElement
	 */
	proto.createFileListFromTemplate = function () {
		var list = this.createFileListElement();

		for (var i = 0; i < this.files.length; i++) {
			var file = this.files[i];
			var listItem = this.createFileListItemElement();
			var dataTransfer = new DataTransfer();

			dataTransfer.items.add(file);

			var fileInput = this.createFileListInputElement(dataTransfer.files);

			listItem.appendChild(fileInput);

			this.addCustomTemplateToListItem(listItem, file);

			var removeButton = listItem.querySelector("[data-file-upload-remove]");

			if (removeButton) {
				removeButton.setAttribute("data-index", i.toString());
				removeButton.addEventListener("click", handleRemoveClickEvent.bind(this));
			}

			list.appendChild(listItem);
		}

		return list;
	};

	/**
	 * Creates the file list from a template
	 * @returns HTMLElement
	 */
	proto.createDefaultFileList = function () {
		var list = this.createFileListElement();

		for (var i = 0; i < this.files.length; i++) {
			var file = this.files[i];
			var listItem = this.createFileListItemElement();
			var removeButton = this.createDefaultRemoveButtonElement(i);
			var fileInformation = this.createDefaultFileInformationElement(file);
			var dataTransfer = new DataTransfer();

			dataTransfer.items.add(file);

			var fileInput = this.createFileListInputElement(dataTransfer.files);

			listItem.appendChild(fileInput);
			listItem.appendChild(fileInformation);
			listItem.appendChild(removeButton);

			list.appendChild(listItem);
		}

		return list;
	};

	/**
	 * Adds the custom template to the file list items
	 */
	proto.addCustomTemplateToListItem = function (listItem, file) {
		var parsedItemTemplate = this.nodeListToArray(this.parseElement(
			this.parseFileListText(this.listItemTemplate, file)
		));

		for (var j = 0; j < parsedItemTemplate.length; j++) {
			listItem.appendChild(parsedItemTemplate[j]);
		}
	};

	/**
	 * Creates the file list element
	 * @returns HTMLElement
	 */
	proto.createFileListElement = function () {
		var list = document.createElement("ul");

		list.classList.add("c-fileUpload__fileList");

		return list;
	};

	/**
	 * Creates the file list item element
	 * @returns HTMLElement
	 */
	proto.createFileListItemElement = function () {
		var listItem = document.createElement("li");

		listItem.classList.add("c-fileUpload__fileListItem");

		return listItem;
	};

	/**
	 * Creates the file list input element
	 * @returns HTMLElement
	 */
	proto.createFileListInputElement = function (files) {
		var fileInput = document.createElement("input");

		fileInput.type = "file";
		fileInput.name = this.options.inputName + "[]";
		fileInput.classList.add("c-fileUpload__input");
		fileInput.accept = this.options.fileTypes;

		if (files) {
			fileInput.files = files;
		}

		return fileInput;
	};

	/**
	 * Creates the default file remove button element
	 * @returns HTMLElement
	 */
	proto.createDefaultRemoveButtonElement = function (index) {
		var removeButton = document.createElement("button");

		removeButton.classList.add("c-fileUpload__remove");
		removeButton.setAttribute("data-index", index);
		removeButton.setAttribute("data-file-upload-remove", "true");
		removeButton.addEventListener("click", handleRemoveClickEvent.bind(this));

		return removeButton;
	};

	/**
	 * Creates the default file information element
	 * @returns HTMLElement
	 */
	proto.createDefaultFileInformationElement = function (file) {
		var fileInformation = document.createElement("span");

		fileInformation.classList.add("c-fileUpload__fileName");
		fileInformation.innerHTML = this.parseFileListText(
			"__fileName__, __fileSize__ (__fileType__)",
			file
		);

		return fileInformation;
	};

	/**
	 * Creates the "Add file" element
	 * @returns HTMLElement
	 */
	proto.createFileAddingButton = function () {
		var addAnotherFileItem = document.createElement("li");
		var addAnotherFileLabel = document.createElement("label");

		addAnotherFileItem.classList.add("c-fileUpload__fileListItem");

		addAnotherFileLabel.innerHTML = "Add file";
		addAnotherFileLabel.classList.add("c-fileUpload__addFile");
		addAnotherFileLabel.setAttribute("for", this.placeholderIdentifier);
		addAnotherFileItem.appendChild(addAnotherFileLabel);

		return addAnotherFileItem;
	};

	/**
	 * Handles files being added to the list
	 * @param {FileList} files
	 */
	proto.handleFiles = function (files) {
		for (var i = 0; i < files.length; i++) {
			if (!this.hitLimit()) {
				var file = files[i];

				if (this.validateFile(file)) {
					this.files.push(file);
				}
			}
		}

		this.parseFilesList();
	};

	/**
	 * Parse the element content
	 * @param element
	 *
	 * @returns NodeList
	 */
	proto.parseElement = function (element) {
		element = Dom.parseAll(this.parsePlaceholderText(element));

		return element;
	};

	/**
	 * Returns the desired slot
	 * @param target
	 * @param slotName
	 * @returns {*} Returns the slot, of if not found, returns false
	 */
	proto.getSlot = function (target, slotName) {
		var elements = Dom.getAll(target);

		for (var i = 0; i < elements.length; i++) {
			if (
				elements[i].dataset
				&& elements[i].dataset.slot
				&& elements[i].dataset.slot === slotName
			) {
				return elements[i];
			}
		}

		return false;
	};

	/**
	 * Converts a NodeList to an Array
	 * @param {NodeList} nodeList
	 * @returns {Array}
	 */
	proto.nodeListToArray = function (nodeList) {
		return Array.prototype.slice.call(nodeList);
	};

	/**
	 * Replaces placeholders with plugin options
	 * @param template
	 */
	proto.parsePlaceholderText = function (template) {
		template = template.replace(
			new RegExp("__inputName__", "g"),
			this.options.inputName
		);

		template = template.replace(
			new RegExp("__fileTypes__", "g"),
			this.options.fileTypes
		);

		template = template.replace(
			new RegExp("__maxFileNumber__", "g"),
			this.options.maxFileNumber
		);

		template = template.replace(
			new RegExp("__maxFileSize__", "g"),
			this.options.maxFileSize
		);

		template = template.replace(
			new RegExp("__additionalText__", "g"),
			this.options.additionalText
		);

		return template;
	};

	/**
	 * Validates file by type and size
	 * @param {File} file
	 * @returns {Boolean} True if file is valid and false if not
	 */
	proto.validateFile = function (file) {
		var splitFileTypes = this.options.fileTypes.split(",");
		var validFileTypes = [];
		var position = 0;

		for (var i = 0; i < splitFileTypes.length; i++) {
			if (splitFileTypes[i].indexOf("/") > -1) {
				position = splitFileTypes[i].indexOf("/");

				validFileTypes.push(splitFileTypes[i].slice(position + 1));
			}
			else if (splitFileTypes[i].indexOf(".") > -1) {
				position = splitFileTypes[i].indexOf(".");

				validFileTypes.push(splitFileTypes[i].slice(position + 1));
			}
		}

		var isValidFileType = (
			splitFileTypes.includes(file.type)
			|| validFileTypes.includes(this.getFileExtension(file.name))
		);

		return isValidFileType && file.size <= this.convertFileSize(this.options.maxFileSize);
	};

	/**
	 * Checks if the file limit number has been reached
	 * @returns {Boolean} True if the limit has been reached
	 */
	proto.hitLimit = function () {
		if (this.maxFileNumber === "infinite") {
			return false;
		}

		return this.files.length === parseInt(this.options.maxFileNumber);
	};

	/**
	 * Replace the string placeholders with the
	 * values from the file in the file list item template
	 * @param {string} template
	 * @param {File} file
	 * @returns {string} template with values inplace
	 */
	proto.parseFileListText = function (template, file) {
		template = template.replace(
			new RegExp("__fileName__", "g"),
			file.name
		);

		template = template.replace(
			new RegExp("__fileSize__", "g"),
			this.humanFileSize(file.size)
		);

		template = template.replace(
			new RegExp("__fileType__", "g"),
			this.getFileExtension(file.name).toUpperCase()
		);

		return template;
	};

	/**
	 * Get the file extension from the file name
	 * @param {string} fileName
	 * @returns {string} the file extension or the file name if no extension was found
	 */
	proto.getFileExtension = function (fileName) {
		return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
	};

	/**
	 * Removes a file from the files list
	 * @param {number} index
	 */
	proto.removeFileFromList = function (index) {
		var files = [];

		for (var i = 0; i < this.files.length; i++) {
			if (index !== i) {
				files.push(this.files[i]);
			}
		}

		if (files.length === 0) {
			this.inputPlaceHolder.value = "";
		}

		this.files = files;

		this.parseFilesList();
	};

	/**
	 * Converts file size into human readable format
	 * @param {number} size
	 * @returns {string} the converted file size
	 */
	proto.humanFileSize = function (size) {
		var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
		var measure = ["B", "KB", "MB", "GB", "TB"][i];

		return (size / Math.pow(1024, i)).toFixed(1) * 1 + " " + measure;
	};

	/**
	 * Converts file size into human readable format
	 * @param {string} size
	 * @returns {number} the converted file size
	 */
	proto.convertFileSize = function (size) {
		var convertedSize = 0;
		var measureModifiers = [];

		measureModifiers["B"] = 1;
		measureModifiers["KB"] = 1024;
		measureModifiers["MB"] = 1024 * measureModifiers["KB"];
		measureModifiers["GB"] = 1024 * measureModifiers["MB"];
		measureModifiers["TB"] = 1024 * measureModifiers["GB"];

		var value = Number(size.slice(0, -2));
		var measure = size.slice(-2);

		if (value && measureModifiers[measure]) {
			return value * measureModifiers[measure];
		}

		value = Number(size.slice(0, -1));
		measure = size.slice(-1);

		if (value && measureModifiers[measure]) {
			convertedSize = value * measureModifiers[measure];
		}

		return convertedSize;
	};

	Plugins.FileUpload = FileUpload;

	/**
	 * Handles changes to the placeholder file input
	 */
	function handleInputPlaceHolderChange(event) {
		this.handleFiles(event.target.files);
	}

	/**
	 * Handles the dragenter event
	 */
	function handleDragEnter(event) {
		event.preventDefault();
		event.stopPropagation();
		highlight(this.wrapper);
	}

	/**
	 * Handles the dragleave event
	 */
	function handleDragLeave(event) {
		event.preventDefault();
		event.stopPropagation();
		unhighlight(this.wrapper);
	}

	/**
	 * Handles the dragover event
	 */
	function handleDragOver(event) {
		event.preventDefault();
		event.stopPropagation();
		highlight(this.wrapper);
	}

	/**
	 * Handles files drop
	 */
	function handleDrop(event) {
		event.preventDefault();
		event.stopPropagation();
		unhighlight(this.wrapper);

		var dataTransfer = event.dataTransfer;
		var files = dataTransfer.files;

		this.handleFiles(files);
	}

	/**
	 * Adds highlight class to an element
	 * @param element
	 */
	function highlight(element) {
		element.classList.add("highlight");
	}

	/**
	 * Removes highlight class from an element
	 * @param element
	 */
	function unhighlight(element) {
		element.classList.remove("highlight");
	}

	/**
	 * Handles click on remove button
	 * @param event
	 */
	function handleRemoveClickEvent(event) {
		event.preventDefault();

		var index = Number(event.target.getAttribute("data-index"));

		this.removeFileFromList(index);
	}
})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
