/* ==============================================================================================
 * GMEI(LEI) REQUEST COMPONENT FEATURE
 * ============================================================================================== */


var addDirectParent = document.querySelector("[name=addDirectParent]");
var addUltimateParent = document.querySelector("[name=addUltimateParent]");
var sameAsDirectParent = document.querySelector("[name=sameAsDirectParent]");

if (addDirectParent) {
	addDirectParent.addEventListener("click", function () {
		var form = document.getElementById("directParentInfo");
		var select = document.getElementById("directParentSelect");

		if (addDirectParent.checked) {
			form.classList.remove("_dn");
			select.classList.add("_dn");
		} else {
			form.classList.add("_dn");
			select.classList.remove("_dn");
		}
	});
}

if (addUltimateParent) {
	addUltimateParent.addEventListener("click", function () {
		var form = document.getElementById("ultimateParentInfo");
		var select = document.getElementById("ultimateParentSelect");

		if (addUltimateParent.checked) {
			form.classList.remove("_dn");
			select.classList.add("_dn");
		} else {
			form.classList.add("_dn");
			select.classList.remove("_dn");
		}
	});
}

if (sameAsDirectParent) {
	sameAsDirectParent.addEventListener("click", function () {
		var div = document.getElementById("sameAsDirectParentInfo");

		sameAsDirectParent.checked
			? div.classList.add("_dn")
			: div.classList.remove("_dn");
	});
}
