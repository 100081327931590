/* ==============================================================================================
 * TABLE OF CONTENTS
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	// Table of contents
	var toc = document.querySelector(".c-toc");

	if (!toc) {
		return;
	}

	// For each TOC link
	Sushi.Dom.forEach(toc.querySelectorAll(".c-toc__link"), function (link) {
		// When it is clicked
		link.addEventListener("click", function (event) {
			var linkSection = link.parentNode.parentNode;

			// Select all children toggleable links
			linkSection.querySelectorAll("[data-plugin='Reveal']").forEach(function (activeLink) {

				// And close them, except for the one the user clicked
				if (activeLink !== event.currentTarget) {
					Sushi.getPluginInstance("Reveal", activeLink).close();
				}
			});
		});
	});

})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
