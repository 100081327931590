(function () {

	var animationContainers = document.querySelectorAll("[data-animate-when-visible]");

	Array.prototype.slice.call(animationContainers).forEach(function (animationContainer) {
		if (!animationContainer.getAttribute("data-animation")) {
			return;
		}

		var animationOffset = animationContainer.getAttribute("data-animation-offset") || 0;
		var animationDelay = animationContainer.getAttribute("data-animation-delay") || 0;
		var animation = window.bodymovin.loadAnimation({
			container: animationContainer,
			renderer: "svg",
			loop: false,
			autoplay: false,
			path: animationContainer.getAttribute("data-animation"),
		});

		animationContainer.innerHTML = "";

		window.addEventListener("scroll", function (event) {
			var rect = animationContainer.getBoundingClientRect();
			var top = rect.top;
			var offsetY = window.pageYOffset;

			if (top < offsetY - animationOffset) {
				setTimeout(function () {
					animation.play();
				}, animationDelay);
			}
		});
	});

})();
