/* =========================================================================
 * VIDEO PLAYER
 * ========================================================================= */

var Sushi;

(function (Sushi) {
	"use strict";

	var mobileMenu = document.querySelector(".c-mobileMenu");
	var openModalButton = document.querySelector(".c-header__mobileMenuToggle");
	var closeModalButton = document.querySelector(".c-mobileMenu__close");

	if (!mobileMenu || !openModalButton || !closeModalButton) {
		return;
	}

	openModalButton.addEventListener("click", function () {
		mobileMenu.classList.add("is-active");
		Sushi.BodyScroll.lock();
	});

	closeModalButton.addEventListener("click", function () {
		mobileMenu.classList.remove("is-active");
		Sushi.BodyScroll.unlock();
	});

})(Sushi || (Sushi = {}));
