var Sushi;

(function (Sushi) {
	"use strict";

	var classes = {
		instance: "c-table",
		head: "c-table__head",
		body: "c-table__body",
		row: "c-table__row",
		rowActive: "c-table__row--active",
		detailRow: "c-table__detailRow",
		check: "c-table__select",
		checkAll: "c-table__select--all",
		checkRow: "c-table__select--row",
		checkedRows: "c-table__select--row:checked",
		ascedingCell: "c-table__cell--sortedAsc",
		descedingCell: "c-table__cell--sortedDesc",
	};

	var sortBy = {
		alpha: function (current, next, direction) {
			return direction === "desc"
				? current.toLowerCase() < next.toLowerCase()
				: current.toLowerCase() > next.toLowerCase();
		},
		date: function (current, next, direction) {
			var currentDateString = current.split("/").reverse().join("");
			var nextDateString = next.split("/").reverse().join("");

			return direction === "desc"
				? currentDateString < nextDateString
				: currentDateString > nextDateString;
		},
	};

	var cellActions = {
		toggleActive: function (cell) {
			cell.parentNode.classList.toggle(classes.rowActive);
		},
	};

	/**
	 * Checks if element is a table detail row
	 * @param {element} rowElement
	 */
	function isDetailRow(rowElement) {
		if (rowElement) {
			return rowElement.classList.contains(classes.detailRow);
		}

		return false;
	}

	/**
	 * @param table - The table element.
	 * @param columnIndex - The order of the clicked column in the table head. Starts from zero.
	 * @param sortFn - The function that compares two values.
	 */
	function sortTable(table, columnIndex, sortFn) {
		var rows, direction;
		var switching, shouldSwitch, switchcount;
		var i, n, currentRow, currentRowValue, nextRow, nextRowValue;

		direction = "asc";
		switching = true;
		switchcount = 0;

		while (switching) {
			switching = false;
			rows = table.querySelectorAll("." + classes.row);

			for (i = 1, n = (rows.length - 1); i < n; i++) {
				shouldSwitch = false;

				currentRowValue = rows[i].cells[columnIndex].innerText;
				nextRowValue = rows[i + 1].cells[columnIndex].innerText;

				if (sortFn(currentRowValue, nextRowValue, direction)) {
					shouldSwitch = true;
					break;
				}
			}

			if (shouldSwitch) {
				currentRow = rows[i];
				nextRow = rows[i + 1];
				var possibleDetailRow = nextRow.nextSibling;

				currentRow.parentNode.insertBefore(nextRow, currentRow);
				if (isDetailRow(possibleDetailRow)) {
					currentRow.parentNode.insertBefore(possibleDetailRow, currentRow);
				}

				switching = true;
				switchcount ++;
			}
			else if (switchcount === 0 && direction === "asc") {
				direction = "desc";
				switching = true;
			}
		}

		var headRows = table.rows[0];

		Sushi.Dom.forEach(headRows.cells, function (cell, i) {
			cell.classList.remove(classes.ascedingCell);
			cell.classList.remove(classes.descedingCell);

			if (i === columnIndex) {
				direction === "asc"
					? cell.classList.add(classes.ascedingCell)
					: cell.classList.add(classes.descedingCell);
			}
		});
	}

	/**
	 * @param table - The table element which contains checkboxes enabled.
	 */
	function updateCheckboxes(table) {
		var checkAllElement = table.querySelector("." + classes.checkAll);
		var checkRowElements = table.querySelectorAll("." + classes.checkRow);
		var checkedRows = 0;

		checkAllElement.addEventListener("click", function () {
			var checkAll = checkAllElement.checked;

			Sushi.Dom.forEach(checkRowElements, function (checkbox) {
				checkbox.checked = checkAll;
			});
		});

		Sushi.Dom.forEach(checkRowElements, function (checkbox) {
			checkbox.addEventListener("click", function () {
				checkedRows = table.querySelectorAll("." + classes.checkedRows).length;

				checkedRows === checkRowElements.length
					? checkAllElement.checked = true
					: checkAllElement.checked = false;
			});
		});
	}

	var tables = document.querySelectorAll("." + classes.instance);

	Sushi.Dom.forEach(tables, function (table) {
		var config = {
			defaultSortMethod: "alpha",
			enableCheckboxes: !!table.querySelector("." + classes.checkAll),
		};
		var isSortable;
		var sortableCells = table.querySelectorAll("[data-sortable]");
		var actionCells = table.querySelectorAll("[data-action]");

		Sushi.Dom.forEach(sortableCells, function (cell, cellIndex) {
			isSortable = !!cell.getAttribute("data-sortable");

			if (! isSortable) {
				return;
			}

			if (config.enableCheckboxes) {
				cellIndex++;
			}

			var sortMethod = cell.getAttribute("data-sort-method") || config.defaultSortMethod;

			cell.addEventListener("click", function () {
				sortTable(table, cellIndex, sortBy[sortMethod]);
			});
		});

		Sushi.Dom.forEach(actionCells, function (cell) {
			var cellAction = cell.getAttribute("data-action");

			if (cellAction) {
				cell.addEventListener("click", function () {
					cellActions[cellAction](cell);
				});
			}
		});

		if (config.enableCheckboxes === true) {
			updateCheckboxes(table);
		}
	});

})(Sushi || (Sushi = {}));
