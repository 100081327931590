/* =========================================================================
 * OVERWRITE SUSHI PLUGINS DEFAULT OPTIONS
 * ========================================================================= */

var Sushi;

(function (Sushi) {
	"use strict";

    if (Sushi.Util.detectIe()) {
        Sushi.Plugins.FileUpload.DEFAULTS.dragAndDrop = false;
    }

})(Sushi || (Sushi = {}));