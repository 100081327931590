/* ==============================================================================================
 * HEADQUARTERS ADDRESS SAME AS LEGAL ADDRESS
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

    var sameAsLegalCheckbox = document.querySelector("[data-input='headquartersAddressSameAsLegalAddress'] input");

    if (!sameAsLegalCheckbox) {
        return;
    }

    var fieldsPairs = [
        {
            legal: document.querySelector("[name='entityLegalCountry']"),
            headquarter: document.querySelector("[name='entityHeadquartersCountry']"),
        },{
            legal: document.querySelector("select[name='entityLegalState']"),
            headquarter: document.querySelector("select[name='entityHeadquartersState']"),
        },{
            legal: document.querySelector("input[name='entityLegalState']"),
            headquarter: document.querySelector("input[name='entityHeadquartersState']"),
        },{
            legal: document.querySelector("[name='entityLegalCity']"),
            headquarter: document.querySelector("[name='entityHeadquartersCity']"),
        },{
            legal: document.querySelector("[name='entityLegalZipCode']"),
            headquarter: document.querySelector("[name='entityHeadquartersZipCode']"),
        },{
            legal: document.querySelector("[name='entityLegalAddressLine1']"),
            headquarter: document.querySelector("[name='entityHeadquartersAddressLine1']"),
        },{
            legal: document.querySelector("[name='entityLegalAddressLine2']"),
            headquarter: document.querySelector("[name='entityHeadquartersAddressLine2']"),
        },{
            legal: document.querySelector("[name='entityLegalAddressLine3']"),
            headquarter: document.querySelector("[name='entityHeadquartersAddressLine3']"),
        },{
            legal: document.querySelector("[name='entityLegalAddressLine4']"),
            headquarter: document.querySelector("[name='entityHeadquartersAddressLine4']"),
        },
    ];

    /**
     * Triggers the event that updates the floating labels/selects
     */
    function updateFieldUiAndLabels(element) {
        var tagName = element.tagName.toLowerCase();
        var eventName = tagName === "select" ? "Select.change" : "FloatingLabel.change";

        Sushi.Events(element).trigger(eventName);
    }

    /**
     * Copies the values from the legal address fields to the headquarter address fields
     */
    function copyLegalAddressValues() {
        for (var i = 0; i < fieldsPairs.length; i++) {
            fieldsPairs[i].headquarter.value = fieldsPairs[i].legal.value;

            updateFieldUiAndLabels(fieldsPairs[i].headquarter);
        }
    }

    /**
     * Clears the values from the headquarter address fields
     */
    function clearHeadquarterFields() {
        for (var i = 0; i < fieldsPairs.length; i++) {
            if (fieldsPairs[i].headquarter.name === "entityHeadquartersCountry") {
                fieldsPairs[i].headquarter.value = "United States";
            }
            else {
                fieldsPairs[i].headquarter.value = "";
            }

            updateFieldUiAndLabels(fieldsPairs[i].headquarter);
        }
    }

    /**
     * Add event listeners
     */
    function addEventListeners() {
        for (var i = 0; i < fieldsPairs.length; i++) {
            if (fieldsPairs[i].legal.tagName.toLowerCase() === "select") {
                fieldsPairs[i].legal.addEventListener("Select.change", copyLegalAddressValues, true);
            }
            else {
                fieldsPairs[i].legal.addEventListener("change", copyLegalAddressValues, true);
            }
        }
    }

    /**
     * Remove event listeners
     */
    function removeEventListeners() {
        for (var i = 0; i < fieldsPairs.length; i++) {
            if (fieldsPairs[i].legal.tagName.toLowerCase() === "select") {
                fieldsPairs[i].legal.removeEventListener("Select.change", copyLegalAddressValues, true);
            }
            else {
                fieldsPairs[i].legal.removeEventListener("change", copyLegalAddressValues, true);
            }
        }
    }

    sameAsLegalCheckbox.addEventListener("click", function () {
        var isChecked = sameAsLegalCheckbox.checked;

        if (isChecked) {
            copyLegalAddressValues();
            addEventListeners();
        }
        else {
            clearHeadquarterFields();
            removeEventListeners();
        }
    });

})(Sushi || (Sushi = {}));
