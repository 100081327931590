/* ==============================================================================================
 * MATURITY DATE AND YEAR
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
    "use strict";

    var Dom = Sushi.Dom;
	var Events = Sushi.Events;

	/**
	 * Maturity Dates
	 */
	function MaturityDates(element) {
		this.element = element;
		this.maturityDate = this.element.querySelector("[data-input='maturityDate'] input");
        this.maturityYear = this.element.querySelector("[data-input='maturityYear'] .c-select");

		if (!this.maturityDate || !this.maturityYear) {
			return;
        }

        this.maturityYearField = this.maturityYear.querySelector("select");

		Events(this.maturityDate).on("change", function () {
            this.maturityYearField.value = "";
            Events(this.maturityYearField).trigger("Select.change");
        }.bind(this));

		Events(this.maturityYear).on("Select.change", function () {
            this.maturityDate.value = "";
        }.bind(this));
	}

	var elements = document.querySelectorAll("[data-feature='maturityDates']");

	Dom.forEach(elements, function (element) {
		new MaturityDates(element);
	});
})(Sushi || (Sushi = {}));
