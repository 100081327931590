/* =========================================================================
 * DETECT IE
 * ========================================================================= */

var Sushi;

(function (Sushi) {
	"use strict";

	/**
	 * Detects if browser is IE
	 */
	Sushi.Util.detectIe = function () {
		var ua = window.navigator.userAgent;

		if (ua.indexOf("MSIE ") > 0) {
			return true;
		}

		if (ua.indexOf("Trident/") > 0) {
			return true;
		}

		if (ua.indexOf("Edge/") > 0) {
			return true;
		}

		return false;
	};

})(Sushi || (Sushi = {}));