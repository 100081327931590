/* ==============================================================================================
 * FULL ADDRESS COMPONENT FEATURE
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var Dom = Sushi.Dom;
	var Events = Sushi.Events;

	/**
	 * FullAddress - Country and state
	 */
	function FullAddress(element) {
		this.element = element;
		this.countrySelector = this.element.querySelector("[data-input='country'] select");
		this.stateSelectContainer = this.element.querySelector("[data-input='stateSelect']");
		this.stateSelect = this.stateSelectContainer.querySelector(".c-select");
		this.stateSelectField = this.stateSelectContainer.querySelector("select");
		this.stateTextContainer = this.element.querySelector("[data-input='stateText']");

		if (!this.countrySelector || !this.stateSelectContainer || !this.stateSelect) {
			return;
		}

		this.defaultValue = this.stateSelectField.value;

		if (this.stateTextContainer) {
			this.stateTextField = this.stateTextContainer.querySelector("input");
		}

		var update = function () {
			if (this.stateTextField) {
				this.stateSelectField.value = this.defaultValue;
				this.stateTextField.value = "";

				Events(this.stateSelectField).trigger("Select.change");
				Events(this.stateTextField).trigger("FloatingLabel.change");

				if (this.countrySelector.selectedOptions[0].value === "United States") {
					this.stateSelectContainer.classList.remove("_dn");
					this.stateTextContainer.classList.add("_dn");
				}
				else {
					this.stateSelectContainer.classList.add("_dn");
					this.stateTextContainer.classList.remove("_dn");
				}
			}
			else {
				this.stateSelectField.value = this.defaultValue;
				Events(this.stateSelectField).trigger("Select.change");

				if (this.countrySelector.selectedOptions[0].value === "United States") {
					this.stateSelectField.required = true;
					this.stateSelect.classList.add("c-input--required");
					Sushi.getPluginInstance(Sushi.Plugins.FloatingLabelSelect, this.stateSelectField).enable();
				}
				else {
					this.stateSelectField.required = false;
					this.stateSelect.classList.remove("c-input--required");
					Sushi.getPluginInstance(Sushi.Plugins.FloatingLabelSelect, this.stateSelectField).disable();
				}
			}
		};

		Events(this.countrySelector).on("Select.change", update.bind(this));
		Events(this.countrySelector).trigger("Select.change");
	}

	var elements = document.querySelectorAll("[data-feature='fullAddress']");

	Dom.forEach(elements, function (element) {
		new FullAddress(element);
	});


	/**
	 * Zipcode
	 */
	function ZipCodeInput(element) {
		this.element = element;
		this.zipcodeInput = element.querySelector("input");

		if (!this.zipcodeInput) {
			return;
		}

		this.zipcodeInputContainer = element.querySelector(".c-input");
		this.countryInputName = element.getAttribute("data-country-input-name");
		this.countryInput = document.querySelector("[name='" + this.countryInputName + "']");
		this.isRequired = !!this.zipcodeInput.hasAttribute("required");
		this.zipcodeErrorMessage = "This field must be a 5 or 9 digit U.S. ZIP Code (like 94043). Please reenter it now.";
		this.zipcodeDelimeter = "-";
		this.validZipcodeLengths = [5, 9];
		this.validationTextElement = this.zipcodeInput.parentNode.parentNode.querySelector(".c-input__validationText");

		if (!this.validationTextElement) {
			this.validationTextElement = document.createElement("DIV");
			this.validationTextElement.classList.add("c-input__validationText");
			this.validationTextElement.classList.add("c-input__validationText--hidden");
			this.validationTextElement.innerHTML = this.zipcodeErrorMessage;
			this.zipcodeInput.parentNode.parentNode.appendChild(this.validationTextElement);
		}

		this.setValid = function () {
			this.validationTextElement.classList.add("c-input__validationText--hidden");
			this.zipcodeInput.parentNode.classList.remove("c-input--validationError");
		};

		this.setInvalid = function () {
			this.validationTextElement.classList.remove("c-input__validationText--hidden");
			this.zipcodeInput.parentNode.classList.add("c-input--validationError");
		};

		this.setRequired = function (value) {
			if (value) {
				this.zipcodeInputContainer.classList.add("c-input--required");
			}
			else {
				this.zipcodeInputContainer.classList.remove("c-input--required");
			}

			this.zipcodeInput.required = value;
		};

		this.addEventListeners = function () {
			Events(this.zipcodeInput).on("Select.blur", validateZipcode.bind(this));

			Events(this.zipcodeInput).on("keyup", function () {
				this.zipcodeInput.value = formatZIPCode(this.zipcodeInput.value, this.zipcodeDelimeter);
			}.bind(this));
		};

		this.removeEventListeners = function () {
			Events(this.zipcodeInput).off("Select.blur");
			Events(this.zipcodeInput).off("keyup");
		};

		if (this.countryInput) {
			Events(this.countryInput).on("Select.change", function () {
				if (this.countryInput.value === "United States") {
					this.addEventListeners();
					this.setRequired(this.isRequired);

					// Trigger events so the elements are updated
					Events(this.zipcodeInput).trigger("Select.blur");
					Events(this.zipcodeInput).trigger("keyup");
				}
				else {
					this.removeEventListeners();
					this.setValid();
					this.setRequired(false);
				}
			}.bind(this));
		}

		this.addEventListeners();
	}

	elements = document.querySelectorAll("[data-input='zipcode']");

	Dom.forEach(elements, function (element) {
		new ZipCodeInput(element);
	});

	/**
	 * Formats the zipcode
	 * Takes a string of 5 or 9 digits and if 9 digits, inserts separator hyphen
	 * @param {string} zipcode
	 * @returns {string} formatted zipcode
	 */
	function formatZIPCode(zipcode, delimiter) {
		zipcode = zipcode.replace(/-| /gi, "");

		if (zipcode.length <= 5 || isNaN(Number(zipcode))) {
			return zipcode;
		}

		return zipcode.substr(0, 5) + delimiter + zipcode.substr(5);
	}

	/**
	 * Validades the zipcode
	 * @param {string} zipcode
	 * @param {boolean} acceptsEmpty if the input can be left empty
	 * @return {boolean} returns true if the value is valid
	 */
	function validateZipcode() {
		var required = this.zipcodeInput.hasAttribute("required");

		if (required === undefined) {
			required = false;
		}

		if (required !== true && !this.zipcodeInput.value) {
			this.setValid();

			return true;
		}

		var normalizedZipcode = this.zipcodeInput.value.replace(/-| /gi, "");

		if (isNaN(Number(normalizedZipcode)) || this.validZipcodeLengths.indexOf(normalizedZipcode.length) === -1) {
			this.setInvalid();

			return false;
		}
		else {
			this.setValid();

			return true;
		}
	}

})(Sushi || (Sushi = {}));
