/* =========================================================================
 * SEARCH BAR
 * ========================================================================= */

(function () {
	"use strict";

	var input = document.querySelector("[data-search-input]");
	var trigger = document.querySelector("[data-search-trigger]");
	var isOpen = false;

	input.addEventListener("click", stopPropagation);
	trigger.addEventListener("click", toggle);
	window.addEventListener("click", close);

	function toggle(event) {
		event.stopPropagation();

		if (isOpen) {
			close();
		}
		else {
			open();
		}

		isOpen = !isOpen;
	}

	function open() {
		trigger.classList.add("is-active");
		input.classList.add("is-active");
	}

	function close() {
		input.classList.remove("is-active");
		trigger.classList.remove("is-active");
	}

	function stopPropagation(event) {
		event.stopPropagation();
	}

})();
