/**
 * @url https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
 */
(function (arr) {
	"use strict";

	arr.forEach(function (item) {
		if (item.hasOwnProperty("remove")) {
			return;
		}

		// eslint-disable-next-line no-console
		console.warn("Polyfilling ChildNode.remove()");

		Object.defineProperty(item, "remove", {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function remove() {
				this.parentNode.removeChild(this);
			},
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
