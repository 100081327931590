/* ==============================================================================================
 * VIDEO COMPONENT
 *
 * Uses plyr plugin to work
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var Dom = Sushi.Dom;

	var videoContainers = Dom.getAll(".c-heroVideo, .c-videoPlayer");

	if (!videoContainers.length) {
		return;
	}

	var Events = Sushi.Events;

	var PlyrHelper = function (container) {
		this.container = container;
		this.plyrElement = container.querySelector("[data-plyr]");
		this.placeholder = container.querySelector("[data-video-placeholder]");
		this.plyr = new window.Plyr(this.plyrElement, {});

		this.registerListeners();
	};

	var proto = PlyrHelper.prototype;

	proto.constructor = PlyrHelper;

	proto.registerListeners = function () {
		if (this.placeholder) {
			Events(this.placeholder).on("click", function () {
				this.container.classList.add("is-loading");

				this.plyr.play();
			}.bind(this));
		}

		this.plyr.on("playing", function () {
			this.container.classList.remove("is-loading");
			this.container.classList.add("is-playing");
		}.bind(this));

		this.plyr.on("ended", function () {
			this.container.classList.remove("is-playing");
		}.bind(this));
	};

	// Auto instantiate
	Dom.forEach(videoContainers, function (container) {
		new PlyrHelper(container);
	});

})(Sushi || (Sushi = {}));
