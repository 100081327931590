/* ==============================================================================================
 * ISSUE TYPE CONTROLLER
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var programTypeSelect = document.querySelector("[data-input='programType'] select");
	var bankBackingLocContainer = document.querySelector("[data-input='bankBackingLoc']");
	var existingCusipContainer = document.querySelector("[data-input='existingCusip']");

	if (!programTypeSelect || !bankBackingLocContainer) {
		return;
	}

	var Events = Sushi.Events;

	var update = function () {
		if (programTypeSelect.selectedOptions[0].value === "3(a)(2)") {
			bankBackingLocContainer.classList.remove("_dn");
			existingCusipContainer.classList.add("_dn");
		}
		else if (programTypeSelect.selectedOptions[0].value === "Re-order") {
			existingCusipContainer.classList.remove("_dn");
			bankBackingLocContainer.classList.add("_dn");
		}
		else {
			existingCusipContainer.classList.add("_dn");
			bankBackingLocContainer.classList.add("_dn");
		}
	};

	Events(programTypeSelect).on("Select.change", update);

	update();
})(Sushi || (Sushi = {}));
