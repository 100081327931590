/* ==============================================================================================
 * REPEATER PLUGIN
 * ============================================================================================== */

var Sushi;

(function (Sushi, Plugins) {
	"use strict";

	var BasePlugin = Plugins.BasePlugin;
	var Dom = Sushi.Dom;
	var Events = Sushi.Events;

	var Repeater = function (triggerElement, options) {
		BasePlugin.call(this, triggerElement, options);

		this.registerListeners();
		this.createMinItems();
	};

	Repeater.displayName = "Repeater";

	Repeater.DEFAULTS = {
		minItems: 1,
	};

	var proto = Repeater.prototype;

	proto.constructor = Repeater;

	proto.getSlot = function (slotName) {
		return this.triggerElement.querySelector("[data-slot='" + slotName + "']");
	};

	proto.registerListeners = function () {
		Events(this.triggerElement).on("Repeater.click", handleClick.bind(this));
	};

	var handleClick = function (event) {
		if ((event.target === this.triggerElement)
			|| (event.target.dataset.onclick === void 0)) {
			return;
		}

		var methodName = getMethodName(event.target.dataset.onclick);

		if (!methodName || (typeof this[methodName] !== "function")) {
			return;
		}

		this[methodName].apply(this, getArgumentsArray(event.target.dataset.onclick));
	};

	var getMethodName = function (actionString) {
		return splitActionString(actionString)[0];
	};

	var splitActionString = function (actionString) {
		return actionString.split(";");
	};

	var getArgumentsArray = function (actionString) {
		return splitActionString(actionString).slice(1);
	};

	proto.add = function (itemData, afterId) {
		if (!this.getSlot("list")) {
			return;
		}

		itemData = itemData || {};

		itemData.id = this.getNextId();

		var itemElement = this.renderItem(itemData);
		var previousItem = this.getItemFromId(afterId);

		itemElement.dataset.id = itemData.id;

		if (previousItem === null) {
			this.getSlot("list").appendChild(itemElement);
		}
		else {
			previousItem.insertAdjacentElement("afterend", itemElement);
		}

		Events(itemElement).trigger("Repeater.add", {
			repeater: this,
		});
	};

	proto.getItemFromId = function (itemId) {
		return this.triggerElement.querySelector("[data-id='" + itemId + "']");
	};

	proto.renderItem = function (data) {
		return this.encloseItem(Dom.getAll(this.populateTemplate(this.getItemTemplate(), data)));
	};

	proto.encloseItem = function (itemElement) {
		var itemContainer = document.createElement("div");

		if (itemElement.length === 1) {
			return itemElement[0];
		}

		while (itemElement.length) {
			itemContainer.appendChild(itemElement[0]);
		}

		return itemContainer;
	};

	proto.populateTemplate = function (template, data) {
		Object.keys(data || {}).forEach(function (dataKey) {
			template = template.replace(new RegExp("__" + dataKey + "__", "g"), data[dataKey]);
		});

		return template;
	};

	proto.getItemTemplate = function () {
		var templateElement = this.triggerElement.querySelector("[data-template='item']");

		if (templateElement === null) {
			return "";
		}

		if (["script", "template"].includes(templateElement.tagName.toLowerCase())) {
			return templateElement.innerHTML;
		}

		return templateElement.outerHTML;
	};

	proto.getNextId = function () {
		var lastId = 0;

		Dom.forEach(this.getAllItems(), function (itemElement) {
			lastId = Math.max(lastId, parseInt(itemElement.dataset.id));
		});

		return lastId + 1;
	};

	proto.getAllItems = function () {
		return this.triggerElement.querySelectorAll("[data-id]");
	};

	proto.remove = function (itemId) {
		var element = this.triggerElement.querySelector("[data-id='" + itemId + "']");

		if (!element) {
			return;
		}

		Events(element).trigger("Repeater.remove", {
			repeater: this,
		});

		element.remove();

		this.createMinItems();
	};

	proto.createMinItems = function () {
		var itemsToCreate = Math.max(this.options.minItems - this.getAllItems().length, 0);

		for (var i = 0; i < itemsToCreate; i++) {
			setTimeout(this.add.bind(this), 0);
		}
	};

	Plugins.Repeater = Repeater;
})(Sushi || (Sushi = {}), Sushi.Plugins || (Sushi.Plugins = {}));
