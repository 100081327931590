/* ==============================================================================================
 * FULL ADDRESS COMPONENT FEATURE
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var saleTypeSelector = document.querySelector("[data-input='saleType'] select");
    var competitiveIssuesRequestorContainer = document.querySelector("[data-input='competitiveIssuesRequestor']");

	var Events = Sushi.Events;

    if (!saleTypeSelector || !competitiveIssuesRequestorContainer) {
        return;
    }

    var update = function () {
        if (saleTypeSelector.selectedOptions[0].value === "C") {
            competitiveIssuesRequestorContainer.classList.remove("_dn");
        }
        else {
            competitiveIssuesRequestorContainer.classList.add("_dn");
        }
    };

    update();

    Events(saleTypeSelector).on("Select.change", update);
})(Sushi || (Sushi = {}));
