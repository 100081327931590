/* ==============================================================================================
 * ISSUE TYPE CONTROLLER
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var issueTypeSelect = document.getElementById("issueType");
	var annuityTypeSelectContainer = document.querySelector("[data-input='issueAnnuityType']");
	var insuranceTypeSelectContainer = document.querySelector("[data-input='issueInsuranceType']");

	if (!issueTypeSelect || !annuityTypeSelectContainer || !insuranceTypeSelectContainer) {
		return;
	}

	var Events = Sushi.Events;

	var update = function () {
		if (issueTypeSelect.selectedOptions[0].value === "annuity") {
			annuityTypeSelectContainer.classList.remove("_dn");
			insuranceTypeSelectContainer.classList.add("_dn");
		}
		else if (issueTypeSelect.selectedOptions[0].value === "insurance") {
			annuityTypeSelectContainer.classList.add("_dn");
			insuranceTypeSelectContainer.classList.remove("_dn");
		}
	};

	Events(issueTypeSelect).on("Select.change", update);

	update();
})(Sushi || (Sushi = {}));
