/* ==============================================================================================
 * CREDIT CARD
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var requestedCusipsInput = document.getElementById("requestedCusips");
	var requestedCusipsValueOutput = document.getElementById("requestedCusipsValue");

	var costPerCusip = 28;
	var baseFee = 163;

	if (!requestedCusipsInput || !requestedCusipsValueOutput) {
		return;
	}

	var Events = Sushi.Events;

	var updateFee = function () {
		requestedCusipsValueOutput.value = "$" + (getCusipValue() + getBaseFeeValue());

		Sushi.getPluginInstance(Sushi.Plugins.FloatingLabel, requestedCusipsValueOutput).update();
	};

	var getCusipValue = function () {
		return (requestedCusipsInput.value * costPerCusip);
	};

	var getBaseFeeValue = function () {
		return (requestedCusipsInput.value && (requestedCusipsInput.value !== "0")
			? baseFee
			: 0
		);
	};

	Events(requestedCusipsInput).on("change", updateFee);

	updateFee();
})(Sushi || (Sushi = {}));
