/* =========================================================================
 * VIDEO PLAYER
 * ========================================================================= */

var Sushi;

(function (Sushi) {
	"use strict";

	function parse(animationContainer) {
		var path = animationContainer.getAttribute("data-animation-path");
		var loop = animationContainer.getAttribute("data-animation-loop") || false;

		animationContainer.innerHTML = "";
		animationContainer.animation = window.bodymovin.loadAnimation({
			container: animationContainer,
			renderer: "svg",
			loop: loop,
			autoplay: true,
			path: path,
			rendererSettings: {
				className: "c-animation__element",
			},
		});
	}

	function refresh() {
		var animationContainers = document.querySelectorAll(".c-animation:not(.is-parsed)");

		animationContainers.forEach(function (animationContainer) {
			animationContainer.classList.add("is-parsed");

			parse(animationContainer);
		});
	}

	if (! Sushi.Util.detectIe()) {
		Sushi.Animations = {
			refresh: refresh,
			parse: parse,
		};

		Sushi.Animations.refresh();
	}
})(Sushi || (Sushi = {}));
