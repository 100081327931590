/* ==============================================================================================
 * FEE CALCULATOR CONTROLLER
 * ============================================================================================== */

var Sushi;

(function () {
	"use strict";

	/**
	 * Fee Calculator
	 * @param {element} element calculator element 
	 */
	function FeeCalculator(element) {
		var selects = element.querySelectorAll("[data-fee-calculator-select]");

		if (!selects.length) {
			return;
		}

		var update = function () {
			updateSelectsState();
			updateTotalDisplay(element);
		};

		Sushi.Events(selects).on("Select.change", update);

		var updateSelectsState = function () {
			var securitiesElement = getSelect("securities");
			var businessLinesElement = getSelect("businessLines");
			var regionsSelectElement = getSelect("regions");

			var securitiesValue = getSelectValue(securitiesElement);
			var businessLinesValue = getSelectValue(businessLinesElement);

			enable(businessLinesElement);
			enable(regionsSelectElement);

			if (!securitiesValue || securitiesValue === 1) {
				disable(businessLinesElement);
				disable(regionsSelectElement);

				return;
			}

			if (!businessLinesValue) {
				disable(regionsSelectElement);
			}
		};

		var updateTotalDisplay = function (element) {
			var total = getTotal();

			if (total === null) {
				total = "-";
			}
			else if (total < 1) {
				total = "Fee Waived";
			}
			else {
				total = formatCurrency(total);
			}

			element.querySelector("#feesResult").innerHTML = total;
		};

		var getTotal = function () {
			var securitiesValue = getSelectValue(getSelect("securities"));
			var businessLinesValue = getSelectValue(getSelect("businessLines"));
			var regionsValue = getSelectValue(getSelect("regions"));

			if (securitiesValue === 1) {
				return 0;
			}

			// Reset Total if no value selected
			if (!businessLinesValue || !regionsValue) {
				return null;
			}

			// Setting result
			var total = 0;

			for (var i = 1; i <= regionsValue; i++) {
				for (var j = 1; j <= businessLinesValue; j++) {
					total += (securitiesValue / (i === 3 ? 4 : i) / (j === 3 ? 4 : j));
				}
			}

			return total;
		};

		var getSelect = function (name) {
			for (var i = 0; i < selects.length; i++) {
				if (selects[i].dataset.feeCalculatorSelect === name) {
					return selects[i];
				}
			}

			return null;
		};

		var formatCurrency = function (value) {
			value = Number(value);

			if (isNaN(value)) {
				return "$0";
			}

			value = Sushi.Util.Math.round(value, 2);

			return value.toLocaleString("en-US", {
				style: "currency",
				currency: "USD",
				maximumFractionDigits: 2,
			}).replace(".00", "");
		};

		window.format = formatCurrency;

		var getSelectValue = function (select) {
			return Number(select.value);
		};

		var disable = function (select) {
			var instance = Sushi.getPluginInstance(Sushi.Plugins.Select, select);

			instance.containerElement.setAttribute("disabled", "disabled");
			instance.buttonElement.setAttribute("disabled", "disabled");
		};

		var enable = function (select) {
			var instance = Sushi.getPluginInstance(Sushi.Plugins.Select, select);

			instance.containerElement.removeAttribute("disabled");
			instance.buttonElement.removeAttribute("disabled");
		};

		update();
	}

	var calculators = document.querySelectorAll("[data-fee-calculator]");

	Sushi.Dom.forEach(calculators, function (calculator) {
		new FeeCalculator(calculator);
	});

})(Sushi || (Sushi = {}));
