/* ==============================================================================================
 * FULL ADDRESS COMPONENT FEATURE
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
	"use strict";

	var terminateDealInput = document.querySelector("[data-input='terminateDeal'] input");
    var terminationDateContainer = document.querySelector("[data-input='terminationDate']");

    if (!terminateDealInput || !terminationDateContainer) {
        return;
    }

    var terminationDateInputWrapper = terminationDateContainer.querySelector(".c-input");
    var terminationDateInput = terminationDateContainer.querySelector("input");

	var Events = Sushi.Events;

    var update = function () {
        if (terminateDealInput.checked) {
            terminationDateContainer.classList.remove("_dn");
            terminationDateInputWrapper.classList.add("c-input--required");
            terminationDateInput.required = true;
        }
        else {
            terminationDateContainer.classList.add("_dn");
            terminationDateInputWrapper.classList.remove("c-input--required");
            terminationDateInput.required = false;
        }
    };

    update();

    Events(terminateDealInput).on("change", update);
})(Sushi || (Sushi = {}));
