(function () {
	"use strict";

	var cookieNotice = document.querySelector("[data-cookie-notice]");
	var acceptButtons = document.querySelectorAll("[data-cookie-notice-accept]");
	var localStorageVariable = "accepted-cookie-notice";

	if (!cookieNotice) {
		return;
	}

	for (var i = 0; i < acceptButtons.length; i++) {
		acceptButtons[i].addEventListener("click", accept);
	}

	function accept() {
		localStorage.setItem(localStorageVariable, "true");
		cookieNotice.classList.remove("is-active");
	}

	if (!JSON.parse(localStorage.getItem(localStorageVariable))) {
		setTimeout(function () {
			cookieNotice.classList.add("is-active");
		});
	}

})();
