/* ==============================================================================================
 * MODIFY BORROWER NAME
 * ============================================================================================== */

var Sushi;

(function (Sushi) {
    "use strict";

    var table = document.querySelector("[data-element='facilityTable']");
    var addButton = document.querySelector("[data-element='addFacility']");
    var removeButton = document.querySelector("[data-element='removeFacility']");
    var modifyButton = document.querySelector("[data-element='modifyFacility']");

	if (!table) {
		return;
    }

    var checkboxes = table.querySelectorAll("input[type='checkbox']");

    Sushi.Dom.forEach(checkboxes, function (checkbox) {
		checkbox.addEventListener("click", function () {
            updateButtons();
        });
    });

    /**
     * Updates action buttons
     */
    function updateButtons () {
        var checked = document.querySelectorAll("input[type='checkbox']:checked");

        if (checked.length) {
            enable(removeButton);
            enable(modifyButton);
            disable(addButton);
        }
        else {
            disable(removeButton);
            disable(modifyButton);
            enable(addButton);
        }
    }

    /**
     * Disables element
     * @param {element} element 
     */
    function disable(element) {
        if (!element) {
            return;
        }

        element.setAttribute("disabled", "disabled");
    }

    /**
     * Enables element
     * @param {element} element 
     */
    function enable(element) {
        if (!element) {
            return;
        }

        element.removeAttribute("disabled");
    }

    updateButtons();

})(Sushi || (Sushi = {}));
