(function() {
	"use strict";

	// SETTINGS
	var minDistance = 80;
	var hiddenClass = "_dn";
	var activeClass = "is-active";
	var maxScrollAttribute = "data-max-scroll";
	var currentScrollAttribute = "data-current-scroll";
	var mobileBreakpoint = 420;

	// Init globals
	var timeline = document.querySelector(".c-timeline");

	if (!timeline) {
		return;
	}

	var timelineDates = timeline.querySelectorAll(".c-timeline__date");
	var timelineEvents = timeline.querySelectorAll(".c-timeline__event");
	var timelineLine = timeline.querySelector(".c-timeline__line");
	var timelineDatesListWrapper = timeline.querySelector(".c-timeline__datesListWrapper");

	if (timelineDates.length === 0) {
		throw Error("Timeline dates not found");
	}

	if (timelineEvents.length === 0) {
		throw Error("Timeline events not found");
	}

	if (!timelineLine) {
		throw Error("Timeline line not found");
	}

	if (!timelineDatesListWrapper) {
		throw Error("Timeline dates list wrapper not found");
	}

	/**
	 * INIT LIST WRAPPER
	 */
	timelineDatesListWrapper.setAttribute(currentScrollAttribute, "0");

	resizeWrapper();

	window.addEventListener("resize", function() {
		resizeWrapper();
		positionDates();
	});

	/**
	 * INIT PREVIOUS AND NEXT BUTTONS
	 */
	var scrollPrevButton = document.querySelector(".c-timeline__button--prev");
	var scrollNextButton = document.querySelector(".c-timeline__button--next");

	if (!scrollPrevButton) {
		throw Error("Timeline scroll prev button not found");
	}

	if (!scrollNextButton) {
		throw Error("Timeline scroll next button not found");
	}

	scrollPrevButton.addEventListener("click", function() {
		var currentScroll = Number(timelineDatesListWrapper.getAttribute(currentScrollAttribute));

		if (currentScroll > 0) {
			scroll(100);
			timelineDatesListWrapper.setAttribute(currentScrollAttribute, String(currentScroll - 1));
		}
	});

	scrollNextButton.addEventListener("click", function() {
		var currentScroll = Number(timelineDatesListWrapper.getAttribute(currentScrollAttribute));
		var maxScroll = Number(timelineDatesListWrapper.getAttribute(maxScrollAttribute));

		if (currentScroll < maxScroll) {
			scroll(-100);
			timelineDatesListWrapper.setAttribute(currentScrollAttribute, String(currentScroll + 1));
		}
	});

	/**
	 * INIT DATES
	 */
	for (var dateIndex = 0; dateIndex < timelineDates.length; dateIndex++) {
		addDateClickListener(dateIndex);
	}

	/** @description
	 */
	function addDateClickListener(index) {
		timelineDates[index].addEventListener("click", function() {

			// hides current event
			getCurrentEvent().classList.add(hiddenClass);
			getCurrentDate().classList.remove(activeClass);

			// shows clicked event
			timelineEvents[index].classList.remove(hiddenClass);
			timelineDates[index].classList.add(activeClass);
		});
	}

	timelineDates[0].classList.add(activeClass);
	positionDates();

	/**
	 * INIT EVENTS
	 */
	for (var eventIndex = 0; eventIndex < timelineEvents.length; eventIndex++) {
		timelineEvents[eventIndex].classList.add(hiddenClass);
	}

	timelineEvents[0].classList.remove(hiddenClass);

	// todo make all items the same height as the taller one

	function resizeWrapper() {
		timelineDatesListWrapper.style.width = timelineLine.offsetWidth + "px";
	}

	function scroll(amount) {
		var timelineDatesList = document.querySelector(".c-timeline__datesList");
		var transformAttr = "data-transform";
		var transform = timelineDatesList.getAttribute(transformAttr);
		var newTransform = Number(transform) + amount;

		timelineDatesList.style.transform = "translateX(" + newTransform + "%)";
		timelineDatesList.setAttribute(transformAttr, newTransform);
	}

	function getCurrentDate() {
		for (var index = 0; index < timelineDates.length; index++) {
			if (timelineDates[index].classList.contains(activeClass)) {
				return timelineDates[index];
			}
		}
	}

	function getCurrentEvent() {
		for (var index = 0; index < timelineEvents.length; index++) {
			if (!timelineEvents[index].classList.contains(hiddenClass)) {
				return timelineEvents[index];
			}
		}
	}

	/**
	 * CALCULATES EACH POSITIONS ON THE TIMELINE
	 */
	function positionDates() {
		var initialValue = getDateValue(timelineDates[0]);
		var lineWidth = timelineLine.offsetWidth;
		var push = minDistance;

		for (var index = 0; index < timelineDates.length; index++) {
			var currentDate = timelineDates[index];
			var value = getDateValue(currentDate);
			var left = push;

			if (isMobile()) {
				currentDate.style.left = (50 + (100 * index)) + "%";
				continue;
			}

			if (index !== 0) {
				var lastValue = getDateValue(timelineDates[index - 1]);

				if (value === lastValue) {
					push += minDistance;
				}

				left = (minDistance * (value - initialValue)) + push;
			}

			var leftModule = left % lineWidth;
			var halfWidth = currentDate.offsetWidth / 2;
			var rightSideClipping = leftModule + halfWidth > lineWidth;
			var leftSideClipping = leftModule < halfWidth;

			if (rightSideClipping) {
				left = left - (halfWidth + leftModule - lineWidth);
			}

			if (leftSideClipping) {
				var diff = halfWidth - leftModule;

				left = left + diff;
			}

			currentDate.style.left = left + "px";

			if (index === timelineDates.length - 1) {
				var lastItemPosition = left;
				var listWidth = Number(timelineDatesListWrapper.style.width.replace("px", ""));
				var maxScroll = String(Math.floor(lastItemPosition / listWidth));

				timelineDatesListWrapper.setAttribute(maxScrollAttribute, maxScroll);
			}
		}
	}

	function isMobile() {
		return window.innerWidth < mobileBreakpoint;
	}

	function getDateValue(element) {
		return element.getAttribute("data-date");
	}

})();
